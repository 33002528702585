<template>
  <div class="my-timetable" :style="`top: ${top}px; right: ${right}px`">
    <!--我的课表-->
    <div class="my-timetable__icon-box" @click="show = !show">
      <div class="my-timetable__icon-box-icon"></div>
      <div class="my-timetable__icon-box-text">我的课表</div>
    </div>
    <transition name="el-fade-in-linear">
      <div v-show="show" class="my-timetable__calendar">
        <el-calendar v-model="choose">
          <template v-slot:dateCell="data"
              >
            <div
                style="color: rgba(255, 255, 255, .7);"
                :class="{'active': data.data.day === moment(choose).format('YYYY-MM-DD')}"
                >
              {{ calendarList.indexOf(data.data.day) !== -1? '课': data.data.day.split('-')[2]}}
            </div>
          </template>
        </el-calendar>
        <div>
          <div @click="choose = null">查看全部</div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  name: "MyTimetable",
  props:{
    top: [String, Number],
    right: [String, Number],
    calendarList: Array,
  },
  data(){
    return {
      show: false,
      choose: null,
      moment,
    }
  },
  watch:{
    choose(v){
      this.show = false
      if (v){
        this.$emit('change', moment(v).format('YYYY-MM-DD'));
      }else{
        this.$emit('change', null);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .my-timetable{
    position: absolute;
    z-index: 99;
    .my-timetable__icon-box{
      cursor: pointer;
      width: 60px;
      height: 87px;
      .my-timetable__icon-box-icon{
        width: 60px;
        height: 60px;
        background: url("../assets/image/ic_cl.png") no-repeat;
        background-size: 100% 100%;
      }
      .my-timetable__icon-box-text{
        font-size: 14px;
        font-family: Microsoft YaHei;
        color: #FFFFFF;
        margin-top: 6px;
      }
    }
    .my-timetable__calendar{
      position: absolute;
      right: 0;
      top: 100px;
      background-color: #7A5FBD;
      border-radius: 30px;
      .el-calendar{
        width: 321px;
        background-color: inherit;
        border-radius: 30px 30px 0 0;
        /deep/ .el-calendar__header{
          display: block;
          padding: 20px 30px 10px;
          .el-calendar__title{
            margin-bottom: 10px;
            color: #FFF;
          }
          .el-button{
            background-color: #7A5FBD;
            &:hover{
              background-color: #974AFF;
            }
            span{
              color: #FFF;
            }
          }
        }
        /deep/ .el-calendar__body{
          //padding: 15px 30px 10px;
          th, .el-calendar-day span{
            color: rgba(255, 255, 255, .7);
          }
          .el-calendar-day{
            height: 35px;
            background-color: #7A5FBD;
            font-size: 12px;
            padding: 0;
            div{
              height: 100%;
              line-height: 35px;
              &.active{
                color: #FFF!important;
                background-color: #974AFF;
              }
            }
          }

        }
      }
      &>:nth-child(2){
        padding: 10px;
        text-align: center;
        div{
          width: 151px;
          height: 43px;
          background: url("../assets/image/bt_rili.png") no-repeat;
          background-size: 100% 100%;
          display: inline-block;
          cursor: pointer;
          font-size: 16px;
          color: #FFFFFF;
          line-height: 43px;
        }
      }
    }

  }

</style>
